import React from "react"
import { ArenguForm } from "gatsby-plugin-arengu-forms"
import { css } from "@emotion/core"

const Fale = () => (
		<div css={css` .af-step-next button[type=submit]{
		background-color: black; color: white;
		font-family: 'Playfair Display',serif ;
		}
		.af-field-input input, textarea, .af-step-failure > p, .af-field-error p, .af-field-label label{
		font-family: 'Playfair Display',serif ;
		}

		.af-step-success {
color: black;
padding: .75em 1em;
border: 1px solid black;
background: transparent;
border-radius: var(--message-border-radius);
font-family: 'Playfair Display',serif ;
}
`}
>
<ArenguForm id="159338806766552542"/>

</div>
)

export default Fale
